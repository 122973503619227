.dsa-secure-plugin {
  border-radius: 5px;
  position: relative;
  padding: 0;
}

.dsa-secure-plugin .dsa-secure-image {
  width: 100%;
  height: 100%;
  background: url("../img/gmap1.png")no-repeat center center / cover;
}

.dsa-secure-plugin {
  font-size: 12px;
  line-height: 16px
}

.dsa-secure-plugin .dsa-buttons {
  width: 90%;
  margin: auto;
  text-align: center
}

.dsa-secure-plugin .dsa-buttons a:nth-of-type(2n) {
  margin-top: 10px
}

.dsa-secure-plugin .undercheck {
  background-color: #fff;
  color: #222 !important;
  border-radius: 5px;
  padding: 4px 4px;
  margin: 8px 50% 16px 50%;
  width: 202px;
  transform: translateX(-50%)
}

.dsa-secure-plugin .check-slide-content {
  position: absolute;
  top: 50%;
  left: 5%;
  width: 90%;
  margin: auto;
  -webkit-transform: translateY(100%);
  transform: translateY(-50%)
}

.dsa-secure-plugin .dsa-switch {
  position: relative;
  width: 60px;
  height: 34px;
  display: block
}

.dsa-secure-plugin .check-slide {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s, opacity .5s linear
}

.dsa-secure-plugin .dsa-secure-plugin input {
  display: none
}

.dsa-secure-plugin iframe {
  width: 100%
}

.dsa-secure-plugin .dsa-switch {
  margin-left: calc(50% - 30px)
}

.dsa-secure-plugin p, .dsa-secure-plugin p {
  text-align: center;
  font-family: arial;
  padding: 10px 0;
  display: block;
  margin: auto;
  font-weight: bold
}

.dsa-secure-plugin .check-slide {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  color: black
}

/* Medium Devices, Desktops */
@media only screen and (min-width : 992px) {

  .dsa-secure-plugin .check-slide {
    width: 50%;
  }

}

.dsa-secure-plugin .check-slide.hide {
  display: none
}

.dsa-secure-plugin a {
  font-size: 12px;
  text-align: center;
  font-family: arial;
  display: block;
  margin: auto;
  width: calc(100% - 20px);
  max-width: 300px;
  background-color: #232323;
  border-radius: 5px;
  padding: 5px 10px;
  text-decoration: none;
  color: white
}

.dsa-secure-plugin a:hover {
  background-color: #373737;
}

.dsa-secure-plugin:hover .check-slide, .dsa-secure-plugin .check-slide:hover {
  visibility: visible;
  opacity: 1;
}

.dsa-secure-plugin:hover .check-slide, .dsa-secure-plugin .check-slide:hover {
  visibility: visible;
  opacity: 1
}

.dsa-secure-plugin .dsa-tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black
}

.dsa-secure-plugin .dsa-tooltip .tooltiptext {
  font-size: 12px;
  visibility: hidden;
  width: 100%;
  max-width: 300px;
  background-color: #232323;
  color: #fff;
  text-align: center;
  padding: 5px 10px;
  border-radius: 6px;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 0;
  margin-left: 0;
  opacity: 0;
  transition: opacity .3s
}

.dsa-secure-plugin .dsa-tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent
}

.dsa-secure-plugin .dsa-tooltip:hover .tooltiptext, .dsa-tooltip.active .tooltiptext {
  visibility: visible;
  opacity: 1
}

.dsa-secure-plugin .dsa-slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #333;
  -webkit-transition: .4s;
  transition: .4s
}

.dsa-secure-plugin .dsa-slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s
}

.dsa-secure-plugin input:checked + .dsa-slider {
  background-color: #232323
}

.dsa-secure-plugin input:focus + .dsa-slider {
  box-shadow: 0 0 1px #232323
}

.dsa-secure-plugin input:checked + .dsa-slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px)
}

.dsa-secure-plugin .dsa-slider.round {
  border-radius: 34px
}

.dsa-secure-plugin .dsa-slider.round:before {
  border-radius: 50%
}
